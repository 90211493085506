import React from 'react'
import { motion } from "framer-motion"
import * as styles from '../styles/snsshare.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const SnsShare = ()=>{

    return(
        <div className={styles.snssharewrapper}>
            <div className={styles.iconlistwrapper}>
                <div className={styles.followme}>
                    follow me!
                </div>

                <motion.div className = {styles.socialmotion}
                    whileHover = {{
                        scale : 1.2,
                        rotate : [0, -7, 7, 0],
                    }}
                >
                    <a 
                        href ="https://twitter.com/moto_kindj"
                        target = {"_blank"}
                        rel = {"noopener"}
                    >
                        <div className = {styles.iconwrapper}>
                            <StaticImage className = {styles.twitter}
                                src="../images/twittericon.png"
                                alt="twitter img"
                                placeholder="none"
                                width={20}
                            />
                        </div>
                    </a>
                </motion.div>

                <motion.div className = {styles.socialmotion}
                    whileHover = {{
                        scale : 1.2,
                        rotate : [0, -7, 7, 0],
                    }}
                >
                    <a 
                        href ="https://www.facebook.com/tomoki.dojo2"
                        target = {"_blank"}
                        rel = {"noopener"}
                    >
                        <div className = {styles.iconwrapper}>
                            <StaticImage className = {styles.twitter}
                                src="../images/facebookicon.png"
                                alt="twitter img"
                                placeholder="none"
                                width={20}
                            />
                        </div>
                    </a>
                </motion.div>

                <motion.div className = {styles.socialmotion}
                    whileHover = {{
                        scale : 1.2,
                        rotate : [0, -7, 7, 0],
                    }}
                >
                    <a 
                        href ="https://www.instagram.com/dojo.20"
                        target = {"_blank"}
                        rel = {"noopener"}
                    >
                        <div className = {styles.iconwrapper}>
                            <StaticImage className = {styles.twitter}
                                src="../images/instagramicon.png"
                                alt="twitter img"
                                placeholder="none"
                                width={20}
                            />
                        </div>
                    </a>
                </motion.div>

                <motion.div className = {styles.socialmotion}
                    whileHover = {{
                        scale : 1.2,
                        rotate : [0, -7, 7, 0],
                    }}
                >
                    <a 
                        href ="https://www.youtube.com/channel/UCgWmX8O5qubjBKTZZ3KvX6Q"
                        target = {"_blank"}
                        rel = {"noopener"}
                    >
                        <div className = {styles.iconwrapper}>
                            <StaticImage className = {styles.twitter}
                                src="../images/youtube.png"
                                alt="youtube img"
                                placeholder="none"
                                width={20}
                            />
                        </div>
                    </a>
                </motion.div>

            </div>

        </div>
    )
}

export default SnsShare