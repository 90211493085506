import React from 'react'
import * as styles from '../styles/footerpagecontents.module.scss'
import { createLinkStyle } from './functions/createconst'

const LinkStyle = createLinkStyle();

const FooterPageContents = ()=>{
    return(
        <div className = {styles.footerpagecontents}>
            <div className = {styles.pagecontents}>
                <a href = {"/form/"} styles={LinkStyle}>お問い合わせ</a>
            </div>
            <div className = {styles.pagecontents}>
                <a href = {"/sitemap/"} styles={LinkStyle}>サイトマップ</a>
            </div>
            <div className = {styles.pagecontents}>
                <a href = {"/profile/"} styles={LinkStyle}>プロフィール</a>
            </div>
            <div className = {styles.pagecontents}>
                <a href = {"/privacypolicy/"} styles={LinkStyle}>プライバシーポリシー</a>
            </div>
        </div>
    )
}

export default FooterPageContents